//import React, { useLayoutEffect, useState } from "react"
import React from "react"
import { inject, observer } from "mobx-react"
import { Link} from "gatsby"

import Layout from "./layout"
import colors from "./colors"
import Newsletter from "./Newsletter"
import ContactBanner from "./ContactBanner"


import ReactMarkdown from "react-markdown/with-html"

// data fields to add: workshop visual, learning goals 1,2,3, testimonial text, testimonial subline, Host tags

const JobDetail = props => {
  const { item} = props.pageContext
  const itemToUse = props.store.language === 'en'? item.en : item.de
  //const en = props.store.language==='en'
  const styles = props.store.device === 'desktop' ? desktopStyles:mobileStyles
  
  return (
    <Layout>
    <img
      style={{width:'100%', maxHeight:'150px', objectFit:'cover'}}
      src={itemToUse.Visual}
      alt="Fans cheering"
    />
      <div style={styles.pageContainer}>
        <div style={styles.contentContainer}>
          <div style={styles.breadCrumbSection}>
            <Link to={"/"} style={styles.link}>
              Home
            </Link>{" "}
            /{" "}
            <Link to={"/careers"} style={styles.link}>
              {" "}
              Careers
            </Link>{" "}
            / {itemToUse.jobTitle}
          </div>
          <h2 style={styles.header}>{itemToUse.jobTitle}</h2>
          {itemToUse.section0 && <div style={styles.infoSection}>
            <div style={styles.infoContainer}>
            <ReactMarkdown
                  source={itemToUse.section0}
                  escapeHtml={false}
                  style={{marginBottom:20}}
                />
              
            </div>
          </div>}
          {itemToUse.section1 && <div style={styles.infoSection}>
            <div style={styles.infoContainer}>
            <ReactMarkdown
                  source={itemToUse.section1}
                  escapeHtml={false}
                  style={{marginBottom:20}}
                />
              
            </div>
          </div>}
          {itemToUse.section2 && <div style={styles.infoSection}>
            <div style={styles.infoContainer}>
            <ReactMarkdown
                  source={itemToUse.section2}
                  escapeHtml={false}
                  style={{marginBottom:20}}
                />
              
            </div>
          </div>}
          {itemToUse.section3 && <div style={styles.infoSection}>
            <div style={styles.infoContainer}>
            <ReactMarkdown
                  source={itemToUse.section3}
                  escapeHtml={false}
                  style={{marginBottom:20}}
                />
              
            </div>
          </div>}
          {itemToUse.section4 && <div style={styles.infoSection}>
            <div style={styles.infoContainer}>
            <ReactMarkdown
                  source={itemToUse.section4}
                  escapeHtml={false}
                  style={{marginBottom:20}}
                />
              
            </div>
          </div>}
          {itemToUse.section5 && <div style={styles.infoSection}>
            <div style={styles.infoContainer}>
            <ReactMarkdown
                  source={itemToUse.section5}
                  escapeHtml={false}
                  style={{marginBottom:20}}
                />
              
            </div>
          </div>}
          <div style={styles.infoSection}>
            <div style={styles.infoContainer}>
            <ReactMarkdown
                  source={itemToUse.contactUsText}
                  escapeHtml={false}
                  style={{marginBottom:20}}
                /> 
              
            </div>
          </div>

        </div>
      </div>
      
      <ContactBanner backgroundColor={colors.white} />
      <Newsletter />
    </Layout>
  )
}

export default inject("store")(observer(JobDetail))

const desktopStyles = {
  pageContainer: { backgroundColor: "white", marginTop:'-10px' },
  contentContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: colors.textDarkgrey,
    padding: "50px 0px",
  },
  breadCrumbSection: { paddingBottom: "50px" },
  link: { color: colors.textDarkgrey, textDecoration: "none" },
  header: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  infoSection: {
    display: "flex",
    flexDirection: "row",
    //alignItems: "flex-end",
  },
  infoContainer: {
    flex: 2.5,
    paddingRight: "50px",
    //paddingTop: '5px',
    paddingTop: '20px',
    paddingBottom: '25px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tagSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  tagContainer: {
    margin: "10px 10px 10px 0px",
    padding: "10px",
    flex: 1,
    maxWidth: "500px",
    minWidth: "200px",
    backgroundColor: colors.lightgrey,
  },
  upperSection: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    margin: "10px 0px",
    padding: "20px",
    height: "55px",
    backgroundColor: colors.gold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  buttonText: {
    fontSize: "18px",
    fontWeight: "bold",
    margin: 0,
    color: colors.white,
  },
  lowerSection: { backgroundColor: colors.greyBackground },
  lowerSectionContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: "black",
    padding: "50px 0px",
  },
  descriptionContainer: { maxWidth: "1200px", marginBottom: '1.45rem' },
  goalsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  hostSectionHeader: {
    fontSize: "1.5625rem",
    color: colors.black,
    margin: "25px 0px 10px",
  },
  testimonial: {
    margin: "50px 0px",
    width: "100%",
    backgroundColor: colors.darkblue,
    color: "white",
    display: "flex",
    padding: "35px 10% 35px 10%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  star: {
    margin: "0px 15px 15px",
    height: "30px",
    width: "35px",
    color: colors.gold,
  },
  testimonialContainer: { marginBottom: "35px" },
  lowerButton: {
    margin: "auto",
    maxWidth: "350px",
    width: "100%",
    height: "55px",
    backgroundColor: colors.gold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: "35",
    cursor: "pointer",
  },
  lowerButtonText: { fontSize: "18px", fontWeight: "bold", margin: 0 },
  hostContainer: { display: "flex", flexDirection: "row", padding: "25px 0px" },
  hostImage: { maxHeight: "225px", maxWidth: "225px", objectFit: "cover" },
  hostTextContainer: { margin: "0px 25px" },
  hostHeader: {
    fontSize: "1.5625rem",
    color: colors.black,
    marginBottom: "10px",
  },
  hostTags: { color: colors.teamTags },
  hostDescription: { color: "#4F4F4F" },
}

const mobileStyles = {
  pageContainer: { backgroundColor: "white", marginTop:'-10px' },
  contentContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: colors.textDarkgrey,
    padding: "50px 0px",
  },
  breadCrumbSection: { paddingBottom: "50px" },
  link: { color: colors.textDarkgrey, textDecoration: "none" },
  header: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  infoSection: {
    display: "flex",
    flexDirection: "column",
    //alignItems: "flex-end",
    
  },
  infoContainer: {
    padding:'20px 0px'
  },
  tagSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  tagContainer: {
    margin: "10px 10px 10px 0px",
    padding: "10px",
    flex: 1,
    maxWidth: "500px",
    minWidth: "300px",
    backgroundColor: colors.lightgrey,
  },
  upperSection: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    margin: "10px 0px",
    padding: "20px",
    height: "55px",
    backgroundColor: colors.gold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontSize: "18px",
    fontWeight: "bold",
    margin: 0,
    color: colors.white,
  },
  lowerSection: { backgroundColor: colors.greyBackground },
  lowerSectionContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: "black",
    padding: "50px 0px",
  },
  descriptionContainer: { maxWidth: "1200px", marginBottom: '1.45rem' },
  goalsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  hostSectionHeader: {
    fontSize: "1.5625rem",
    color: colors.black,
    margin: "25px 0px 10px",
  },
  testimonial: {
    margin: "50px 0px",
    width: "100%",
    backgroundColor: colors.darkblue,
    color: "white",
    display: "flex",
    padding: "35px 10% 35px 10%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  star: {
    margin: "0px 5px 5px",
    height: "30px",
    width: "35px",
    color: colors.gold,
  },
  testimonialContainer: { marginBottom: "35px" },
  lowerButton: {
    margin: "auto",
    maxWidth: "350px",
    width: "100%",
    height: "55px",
    backgroundColor: colors.gold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: "35",
  },
  lowerButtonText: { fontSize: "18px", fontWeight: "bold", margin: 0 },
  hostContainer: { padding: "25px 0px" },
  hostImage: { maxHeight: "225px", maxWidth: "225px", objectFit: "cover" },
  hostTextContainer: { margin: "10px 0px" },
  hostHeader: {
    fontSize: "1.5625rem",
    color: colors.black,
    marginBottom: "10px",
  },
  hostTags: { color: colors.teamTags },
  hostDescription: { color: "#4F4F4F" },
}
